import { Result } from '~/ts-utils';
import { initDebouncedPubSub } from '~commons/debounced-pub-sub';

// It abstracts away the complexity of the configuration setting via exports
// Each parameter is set separately, so here we try to provide batching for
// changes and possibility to to get parameters when they are set

export type Config = {
  namespace: string;
  resourceId: string;
};

export const createConfigurationStore = ({
  defaultNamespace,
  defaultResourceId,
  defaultWaitForResourceId,
}: {
  defaultNamespace: string;
  defaultResourceId: string;
  defaultWaitForResourceId: boolean;
}) => {
  const state = {
    waitForResourceId: defaultWaitForResourceId,
  };
  let configState:
    | { type: 'initialized'; value: Config }
    | {
        type: 'not_initialized';
        value: Config;
      } = {
    type: 'not_initialized',
    value: {
      namespace: defaultNamespace,
      resourceId: defaultResourceId,
    },
  };
  const pubSub = initDebouncedPubSub<Config>();

  return {
    setNamespace: (namespace: string) => {
      if (!namespace) {
        throw new Error('Namespace cannot be empty');
      }
      configState.value.namespace = namespace;
      if (!state.waitForResourceId || configState.type === 'initialized') {
        pubSub.publish(configState.value);
      }
    },
    setResourceId: (resourceId: string) => {
      if (!resourceId) {
        throw new Error('Resource ID cannot be empty');
      }
      configState = {
        type: 'initialized',
        value: {
          ...configState.value,
          resourceId,
        },
      };
      pubSub.publish(configState.value);
    },
    setWaitForResourceId: (value: boolean) => {
      state.waitForResourceId = value;
    },
    getConfiguration: async (): Promise<Result<Config>> => {
      return configState.type !== 'initialized' && state.waitForResourceId
        ? pubSub.waitForFirstPub(() => configState.type === 'initialized')
        : { type: 'ok', value: configState.value };
    },
    getConfigState: () => ({ ...configState }),
    getState: () => ({ ...state }),
    subscribe: pubSub.subscribe,
  };
};
